import {
  SET_USER_ROLE,
  SET_HERO_STAT_CONFIG,
  SET_HERO_CONFIG,
  SET_HERO_LIST,
  SET_RUNE_CONFIG,
  SET_ITEM_CONFIG,
  SET_HERO_STATIC,
  SET_CLASS_CONFIG,
  SET_ORIGIN_CONFIG,
  UPDATE_USER_IN_GAME,
  SET_LOGIN,
  SET_PERMISSION,
  SET_ROLE_NAME
} from './constants'

const initialState = {
  userRole: 0,
  heroConfig: [],
  heroList: [],
  heroStatConfig: [],
  itemConfig: [],
  runeConfig: [],
  classConfig: [],
  originConfig: [],
  heroStatic: [],
  userInfo: [],
  userPermission: [],
  roleName: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ROLE:
      return { ...state, userRole: action.payload }

    case SET_HERO_LIST:
      return { ...state, heroList: action.payload }

    case SET_HERO_CONFIG:
      return { ...state, heroConfig: action.payload }

    case SET_HERO_STAT_CONFIG:
      return { ...state, heroStatConfig: action.payload }

    case SET_ITEM_CONFIG:
      return { ...state, itemConfig: action.payload }

    case SET_RUNE_CONFIG:
      return { ...state, runeConfig: action.payload }

    case SET_CLASS_CONFIG:
      return { ...state, classConfig: action.payload }

    case SET_ORIGIN_CONFIG:
      return { ...state, originConfig: action.payload }

    case SET_HERO_STATIC:
      return { ...state, heroStatic: action.payload }

    case UPDATE_USER_IN_GAME:
      return { ...state, userInfo: action.payload }

    case SET_LOGIN:
      return { ...state, isLogin: action.payload }
    case SET_PERMISSION:
      return { ...state, userPermission: action.payload }
    case SET_ROLE_NAME:
      return { ...state, roleName: action.payload }
    default:
      return state
  }
}

export { initialState }
export default reducer
