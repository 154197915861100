import axiosClient from './axiosClient'
import queryString from 'query-string'

const testLoginURL = 'http://192.168.100.88:8080'
const zone2URL = 'https://api-zone2.heroestd.com'
const betaURL = 'https://beta-dot-heroestd-backup.et.r.appspot.com'
const testVinhLeURL = 'http://25.6.201.104:8080'
const testVinhNQURL = 'http://25.15.206.187:8080'

class HeroestdApi {
  getHeroConfig = () => {
    const url = `/get-hero-config`
    return axiosClient.get(url)
  }

  getHeroStatConfig = () => {
    const url = `/get-hero-stats-config`
    return axiosClient.get(url)
  }

  getHeroStatic = () => {
    const url = `/get-hero-static`
    return axiosClient.get(url)
  }

  getItemConfig = () => {
    const url = `/get-item-config`
    return axiosClient.get(url)
  }

  getRuneConfig = () => {
    const url = `/get-rune-config`
    return axiosClient.get(url)
  }

  getOriginConfig = () => {
    const url = `/get-origin-config`
    return axiosClient.get(url)
  }

  getClassConfig = () => {
    const url = `/get-class-config`
    return axiosClient.get(url)
  }

  getDetailtRolePermission = () => {
    const url = `/getDetailtRolePermissionByCode`
    return axiosClient.get(url)
  }

  getRecentPVPMatch = (params) => {
    const qs = queryString.stringify(params)
    const url = `/get-recent-pvp-match/${qs}`
    return axiosClient.get(url)
  }

  getDetailtMatchrecordlogs = (params) => {
    const qs = queryString.stringify(params)
    const url = `getDetailtMatchrecordlogs/${qs}`
    return axiosClient.get(url)
  }

  getRecentCoopMatch = (params) => {
    const qs = queryString.stringify(params)
    const url = `/get-recent-coop-match/${qs}`
    return axiosClient.get(url)
  }

  getUserPVPMatch = (params) => {
    const qs = queryString.stringify(params)
    const url = `/get-user-pvp-match/${qs}`
    return axiosClient.get(url)
  }

  getUserCoopMatch = (params) => {
    const qs = queryString.stringify(params)
    const url = `/get-user-coop-match/${qs}`
    return axiosClient.get(url)
  }

  getHeroMatch = (params) => {
    const url = `/get-hero-info/${params}`
    return axiosClient.get(url)
  }

  getLeaderboard = (params) => {
    const qs = queryString.stringify(params)
    const url = `/get-pvp-leaderboard-page/${qs}`
    return axiosClient.get(url)
  }

  getBanList = (params) => {
    const qs = queryString.stringify(params)
    const url = `/getBanList/${qs}`
    return axiosClient.get(url)
  }

  getWarningList = (params) => {
    const qs = queryString.stringify(params)
    const url = `/getWarningList/${qs}`
    return axiosClient.get(url)
  }

  removeWarningByID = (params) => {
    const url = `/removeWarningByID/${params}`
    return axiosClient.post(url)
  }

  getListInvitedUser = (params, idToken) => {
    const url = `/getUserReferralPointAdmin/userID=${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getUserMKBuy = (params, idToken) => {
    const url = `/getUserMKBuy/userID=${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getUserMKSell = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getUserMKSell/userID=${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListHeroesOfUser = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getHeroInventoryAdmin/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  removeHeroInventory = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/deleteHeroIGC/${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.delete(url, config)
  }

  UpdateHeroInventory = (params, body, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/updateHeroIGC/${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  CreateEmojiIGC = (body, idToken) => {
    const url = `/createEmojiShopIGC`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  CreateEmojiHTD = (body, idToken) => {
    const url = `/createEmojiShopHTD`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  CreateEmojiCGC = (body, idToken) => {
    const url = `/createEmojiShopCGC`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  CreateEmojiIAP = (body, idToken) => {
    const url = `/createEmojiShopIAP`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  getListShopEmoji = (param, idToken) => {
    const qs = queryString.stringify(param)
    const url = `/getlistShopEmoji/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEmojiHTD = (idToken) => {
    const url = `/getShopEmojiHTD`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEmojiIGC = (idToken) => {
    const url = `/getShopEmojiIGC`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEmojiCGC = (idToken) => {
    const url = `/getShopEmojiCGC`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEmojiIAP = (idToken) => {
    const url = `/getShopEmojiIAP`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getDetailEmojiConfig = (param, idToken) => {
    const url = `/getDetailEmojiConfig/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getDetailHeroSkinConfig = (param, idToken) => {
    const url = `/getDetailHeroSkinConfig/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEmojiHTDDetail = (param, idToken) => {
    const url = `/getDetailShopEmojiHTD/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEmojiIGCDetail = (param, idToken) => {
    const url = `/getDetailShopEmojiIGC/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEmojiCGCDetail = (param, idToken) => {
    const url = `/getDetailShopEmojiCGC/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEmojiIAPDetail = (param, idToken) => {
    const url = `/getDetailShopEmojiIAP/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  deleteEmojiHTD = (param, idToken) => {
    const url = `/deleteShopEmojiHTD/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.delete(url, config)
  }

  deleteEmojiIGC = (param, idToken) => {
    const url = `/deleteShopEmojiIGC/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.delete(url, config)
  }

  deleteEmojiCGC = (param, idToken) => {
    const url = `/deleteShopEmojiCGC/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.delete(url, config)
  }

  deleteEmojiIAP = (param, idToken) => {
    const url = `/deleteShopEmojiIAP/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.delete(url, config)
  }

  updateEmojiHTD = (body, idToken) => {
    const url = `/updateShopEmojiHTD`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateEmojiIGC = (body, idToken) => {
    const url = `/updateShopEmojiIGC`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateEmojiCGC = (body, idToken) => {
    const url = `/updateShopEmojiCGC`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateEmojiIAP = (body, idToken) => {
    const url = `/updateShopEmojiIAP`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  getEmojiConfig = (idToken) => {
    const url = `/getListEmojiConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListWithrawOnus = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getOmusWithdrawRecord/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListDepositOnus = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getOnusDepositRecord/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  searchUser = (params) => {
    const qs = queryString.stringify(params)
    const url = `/searchUser/${qs}`
    return axiosClient.get(url)
  }

  getUserActivity = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `getUserActivity/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getUserHeroesSaleHistory = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getHeroSaleHistories/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getHeroesDetailSaleHistory = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getDetailtHeroSale/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getPriceHeroesSaleHistory = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getPriceHeroSaleHistories/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getUserInfo = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getUserInfo/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    if (params === undefined) {
      return
    } else {
      return axiosClient.get(url, config)
    }
  }

  getUserRole = (idToken) => {
    const url = `/get-user-role`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  loginWithToken = (idToken) => {
    const url = `/login`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }

    const data = {
      deviceID: 'dapp',
    }
    return axiosClient.post(url, data, config)
  }

  banUser = (body, idToken) => {
    const url = `/banUser`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  listLogUserBan = (params = {}) => {
    const qs = queryString.stringify(params)
    const url = `/listLogUserBan/${qs}`
    return axiosClient.get(url)
  }

  updateAdittionUser = (body, userID, idToken) => {
    const qs = queryString.stringify(userID)
    const url = `/updateAdittionUser/${userID}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateUserInfo = (body, userID, idToken) => {
    const qs = queryString.stringify(userID)
    const url = `/updateUserByID/${userID}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  AddMail = (body, idToken) => {
    const url = `/addMailUser`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  addMailAll = (body, idToken) => {
    const url = `/addMailAllUsers`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  checkEmojiUserExist = (params, idToken) => {
    const url = `/checkEmojiUser/${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListMailEvent = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/listMailEvent/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListAllMail = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListAllMail/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  deleteMaileventByUserID = (param, idToken) => {
    const url = `/deleteMaileventByUserID/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.delete(url, config)
  }

  getGetMailDetail = (mailEventID, idToken) => {
    const url = `/getDetailtMailEvent/${mailEventID}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  createReward = (body, idToken) => {
    const url = `/createReward`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  claimReward = (body, idToken) => {
    const url = `/claimReward`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateReward = (id, body, idToken) => {
    const url = `/updateReward/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  getListReward = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListReward${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListOfferShop = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListOfferShop${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getDetailOfferShop = (params, idToken) => {
    const url = `/getDetailOfferShop/${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListItemShop = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListItemShops${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListMapHeroShop = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListMapHeroShop${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListItemDailyShop = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListItemDailyShop${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListItemCurrencyShop = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListItemCurrencyShop${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListEmojiShop = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListEmojiShop${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListPackageIAP = (idToken) => {
    const url = `/getListPackageIAP`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListConfigHeroPrice = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListConfigHeroPrice${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getCurrencyShopLog = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getCurrencyShopLog${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getDailyShopLog = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getDailyShopLog${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getItemShopLog = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getItemShopLog${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEmojiShopLog = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getEmojiShopLog${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getOfferShopLog = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getOfferShopLog${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  createHeroSkinShop = (body, idToken) => {
    const url = `/createHeroSkinShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateShopHeroSkin = (body, idToken) => {
    const url = `/updateShopHeroSkin`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deleteHeroSkinShop = (params, idToken) => {
    const url = `/deleteHeroSkinShop/${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  createOfferShop = (body, idToken) => {
    const url = `/createOfferShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateOfferShop = (body, idToken) => {
    const url = `/updateOfferShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deleteOfferShop = (params, idToken) => {
    const url = `/deleteOfferShop/${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  createItemShop = (body, idToken) => {
    const url = `/createItemShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateItemShop = (body, idToken) => {
    const url = `/updateItemShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  deleteItemShop = (id, idToken) => {
    const url = `/deleteItemShop/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  createCurrencyShop = (body, idToken) => {
    const url = `/createItemCurrencyShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  updateCurrencyShop = (body, idToken) => {
    const url = `/updateItemCurrencyShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deleteCurrencyShop = (id, idToken) => {
    const url = `/deleteItemCurrencyShop/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  createDailyShop = (body, idToken) => {
    const url = `/createItemDailyShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  updateDailyShop = (body, idToken) => {
    const url = `/updateItemDailyShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deleteDailyShop = (id, idToken) => {
    const url = `/deleteItemDailyShop/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  createEmojiShop = (body, idToken) => {
    const url = `/createItemEmojiShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  updateEmojiShop = (body, idToken) => {
    const url = `/updateItemEmojiShop`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deleteEmojiShop = (id, idToken) => {
    const url = `/deleteItemEmojiShop/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  createHeroShop = (body, idToken) => {
    const url = `/createConfigHeroPrice`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  updateHeroShop = (body, idToken) => {
    const url = `/updateConfigHeroPrice`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deleteConfigHeroPriceShop = (id, idToken) => {
    const url = `/deleteConfigHeroPrice/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  createPackageIAP = (body, idToken) => {
    const url = `/createPackageIAP`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  updatePackageIAP = (body, idToken) => {
    const url = `/updatePackageIAP`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deletePackageIAP = (id, idToken) => {
    const url = `/deletePackageIAP/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  getListShopEventConfig = (idToken) => {
    const url = `/getListShopEventConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }
  updateShopEventConfig = (body, idToken) => {
    const url = `/updateShopEventConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  createShopEventConfig = (body, idToken) => {
    const url = `/createShopEventConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  getLinkImg = (body, idToken) => {
    const url = `/getLinkImg`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  createWeekendEventData = (body, idToken) => {
    const url = `/createWeekendEventData`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  getListEventQuestConfig = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListEventQuestConfig${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  updateEventQuestConfig = (body, idToken) => {
    const url = `/updateEventQuestConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  createEventQuestConfig = (body, idToken) => {
    const url = `/createEventQuestConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deleteEventQuestConfig = (id, idToken) => {
    const url = `/deleteEventQuestConfig/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  getListEventHeroQuestConfig = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListEventHeroQuestConfig${qs && '/' + qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  updateEventHeroQuestConfig = (body, idToken) => {
    const url = `/updateEventHeroQuestConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  createEventHeroQuestConfig = (body, idToken) => {
    const url = `/createEventHeroQuestConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deleteHeroEventQuestConfig = (id, idToken) => {
    const url = `/deleteHeroEventQuestConfig/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, config)
  }

  // deletePackageIAP = (id, idToken) => {
  //   const url = `/deletePackageIAP/${id}`
  //   const config = {
  //     headers: { Authorization: `Bearer ${idToken}` },
  //   }
  //   return axiosClient.post(url, config)
  // }

  getListMapSkinConfig = (idToken) => {
    const url = `/getListMapSkinConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListHeroSkinConfig = (idToken) => {
    const url = `/getListHeroSkinConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getDetailReward = (id, idToken) => {
    const url = `/getDetailReward/${id}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  createLevelUserConfig = (body, idToken) => {
    const url = `/createLevelUserConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  createLevelUserConfig = (body, idToken) => {
    const url = `/createLevelUserConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  createMapSkin = (body, idToken) => {
    const url = `/createDailyConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateMapSkin = (body, idToken) => {
    const url = `/updateDailyConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  getListMapSkin = (idToken) => {
    const url = `/getListDailyConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  createDailyConfig = (body, idToken) => {
    const url = `/createDailyConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateDailyConfig = (body, idToken) => {
    const url = `/updateDailyConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  getDetailDailyConfig = (params, idToken) => {
    // const qs = queryString.stringify(params)
    const url = `/getDetailDailyConfig/${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListDailyConfig = (idToken) => {
    const url = `/getListDailyConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  updateStatusReportUser = (params, body, idToken) => {
    // const qs = queryString.stringify(params)
    const url = `/updateStatusReportUser/${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  getDetailtReportUser = (params, idToken) => {
    // const qs = queryString.stringify(params)
    const url = `/getDetailtReportUser/${params}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getListReportUser = (params, idToken) => {
    const qs = queryString.stringify(params)
    const url = `/getListReportUser/${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  unbanUser = (body, idToken) => {
    const url = `/unbanUser`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateConfig = (body, idToken) => {
    const url = `/updateGameConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  getBroadCastMessage = (idToken) => {
    const url = `/getBroadCastMessage`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getNewLink = (params, idToken) => {
    const qs = queryString.stringify(params)
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    const url = `/getNewsLinkData/${qs}`
    return axiosClient.get(url, config)
  }

  updateBroadCastMessage = (body, idToken) => {
    const url = `/updateBroadCastMessage`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  AddNewLink = (body, idToken) => {
    const url = `/updateNewsLinkData`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  postBoardCastMessage = (authen) => {
    const url = `/postBoardCastMessage`
    const config = {
      headers: { Authorization: `Bearer ${authen}` },
    }
    return axiosClient.post(url, config)
  }

  getGameConfig = () => {
    const url = `/game-config`
    return axiosClient.get(url)
  }
  getSessionLeaderboard = (params) => {
    const qs = queryString.stringify(params)
    const url = `/get-session-leaderboard/${qs}`
    return axiosClient.get(url)
  }

  addCustomEventConfig = (body, idToken) => {
    const url = `/addCustomEventConfig`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  getAllEvent = (idToken) => {
    const url = `/getAllEvent`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getEventDetail = (param, idToken) => {
    const url = `/getEventDetail/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  addCustomEventDetails = (body, idToken) => {
    const url = `/addCustomEventDetails`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  updateCustomEventDetails = (body, idToken) => {
    const url = `/updateCustomEventDetails`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }

  deleteCustomEvent = (body, idToken) => {
    const url = `/deleteCustomEvent`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.post(url, body, config)
  }
  getInventoryByUserID = (param, idToken) => {
    const url = `/getInventoryByUserID/${param}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  getBotProfile = (param) => {
    const qs = queryString.stringify(param)
    const url = `/getBotProfile/${qs}`
    return axiosClient.get(url)
  }

  getReportShop = (params) => {
    const qs = queryString.stringify(params)
    const url = `${zone2URL}/getReportShop/${qs}`
    return axiosClient.get(url)
  }
}

const heroestdApi = new HeroestdApi()
export default heroestdApi
