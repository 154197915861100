export const WARNING_REASON = {
  0: 'None',
  1: 'Cheat Money',
  2: 'Cheat HP Hero',
  3: 'Cheat Dame',
  4: 'Cheat Star',
  5: 'Cheat Level Update',
  6: 'Cheat Wave',
  7: 'Cheat HP Bot',
  200: 'Rage Quit',
  404: 'Cheat Hero Team',
  201: 'Cheat Energy Quit'
}

export const BAN_REASON = {
  0: 'Game Cheater',
  1: 'Invail User Name',
  2: 'Transfer NFT Hero multi-account',
  3: 'Withdraw Cheat',
  99: 'Another Reason',
}

export const UNBAN_DATE = {
  1: '1 Date',
  7: '7 Date',
  14: '14 Date',
  30: '30 Date',
  '-1': 'Forever',
}
