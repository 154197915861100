import { useReducer } from 'react'
// import Context from './Context'
import { StateContext, DispatchContext } from './Context'
import retudcer, { initialState } from './reducer'

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(retudcer, initialState)
  // return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export default Provider
