import axios from 'axios'
import { VERSION_API } from 'utils/constants'
import { FIREBASE_API_KEY, REFRESH_TOKEN, TOKEN_ID } from 'contants'
// import queryString from 'query-string'
import { getCookie, setCookie } from 'utils'
import {toast} from "react-toastify"
import { Navigate } from 'react-router-dom'


const testLoginURL = 'http://192.168.100.88:8080'
const betaURL = "https://beta-dot-heroestd-backup.et.r.appspot.com"
const testVinhLeURL = 'http://25.6.201.104:8080'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_URL_API_PROD,
  // baseURL: betaURL,
  headers: {
    'content-type': 'application/json',
    'Device-Type': 'Webapp',
    version: VERSION_API,
  },
  // paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use(async (config) => {
  const token = getCookie(TOKEN_ID) || undefined
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data
    }

    return response
  },
  (error) => {
    // Handle errors
    let errorMessage = error
    if (error.response) {
      const originalRequest = error.config
      if (error?.response?.status === 403 && error?.response?.data?.err === 'tokenExpired') {
        if (getCookie(REFRESH_TOKEN)) {
          const refreshtoken = getCookie(REFRESH_TOKEN)
          const params = {
            grant_type: 'refresh_token',
            refresh_token: refreshtoken,
          }
          axios
            .post(`https://securetoken.googleapis.com/v1/token?key=${FIREBASE_API_KEY}`, params)
            .then((res) => {
              setCookie(TOKEN_ID, res?.data.id_token)
              setCookie(REFRESH_TOKEN, res?.data.refresh_token)
              console.log("unauthorize", error?.response?.status)
              return axios(originalRequest)
            })
            .catch((err) => {
              console.log(err, 'error')
            })
        }
      }
      if ( error?.response?.status === 401 ) {
        console.log("unauthorize", error?.response?.status)
      }
      if ( error?.response?.status === 503 ) {
        toast.error("Server in under maintain")
      }
      errorMessage = error.response.data ? error.response.data.error : error.response.data
    }
    throw new Error(errorMessage)
  },
)

export default axiosClient
