export const SET_USER_ROLE = 'SET_USER_ROLE'
export const SET_HERO_CONFIG = 'SET_HERO_CONFIG'
export const SET_HERO_STAT_CONFIG = 'SET_HERO_STAT_CONFIG'
export const SET_ITEM_CONFIG = 'SET_ITEM_CONFIG'
export const SET_RUNE_CONFIG = 'SET_RUNE_CONFIG'
export const SET_HERO_LIST = 'SET_HERO_LIST'
export const SET_HERO_STATIC = 'SET_HERO_STATIC'
export const SET_CLASS_CONFIG = 'SET_CLASS_CONFIG'
export const SET_ORIGIN_CONFIG = 'SET_ORIGIN_CONFIG'
export const UPDATE_USER_IN_GAME = 'UPDATE_USER_IN_GAME'
export const SET_LOGIN = 'SET_LOGIN'
export const SET_PERMISSION = 'SET_PERMISSION'
export const SET_ROLE_NAME = 'SET_ROLE_NAME'
