import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from 'utils/web3React'
import 'tailwindcss/tailwind.css'
import 'style/HeroCard/heroesCard.modules.scss'
import 'style/Fustion/heroesCardUI.modules.scss'
import 'style/HeroDetail/heroesDetails.modules.scss'
import App from './App'
import { StoreProvider } from 'store'

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
