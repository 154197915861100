import {
  SET_USER_ROLE,
  SET_HERO_STAT_CONFIG,
  SET_HERO_CONFIG,
  SET_HERO_LIST,
  SET_RUNE_CONFIG,
  SET_ITEM_CONFIG,
  SET_HERO_STATIC,
  SET_CLASS_CONFIG,
  SET_ORIGIN_CONFIG,
  UPDATE_USER_IN_GAME,
  SET_LOGIN,
  SET_PERMISSION,
  SET_ROLE_NAME
} from './constants'

export const setUserRole = (payload) => ({
  type: SET_USER_ROLE,
  payload,
})

export const setHeroConfig = (payload) => ({
  type: SET_HERO_CONFIG,
  payload,
})

export const setHeroStatConfig = (payload) => ({
  type: SET_HERO_STAT_CONFIG,
  payload,
})

export const setItemConfig = (payload) => ({
  type: SET_ITEM_CONFIG,
  payload,
})

export const setRuneConfig = (payload) => ({
  type: SET_RUNE_CONFIG,
  payload,
})
export const setClassConfig = (payload) => ({
  type: SET_CLASS_CONFIG,
  payload,
})
export const setOriginConfig = (payload) => ({
  type: SET_ORIGIN_CONFIG,
  payload,
})

export const setHeroList = (payload) => ({
  type: SET_HERO_LIST,
  payload,
})

export const setHeroStatic = (payload) => ({
  type: SET_HERO_STATIC,
  payload,
})

export const updateUserInGame = (payload) => ({
  type: UPDATE_USER_IN_GAME,
  payload,
})

export const setLogin = (payload) => ({
  type: SET_LOGIN,
  payload,
})
export const setPermission = (payload) => ({
  type: SET_PERMISSION,
  payload,
})

export const setRoleName = (payload) => ({
  type: SET_ROLE_NAME,
  payload,
})
