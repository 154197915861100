import React, { lazy } from 'react'

const CardInfo = lazy(() => import('views/CardInfo/CardInfo'))
const HeroDetails = lazy(() => import('views/HeroDetails/HeroDetails'))
const HeroDetail = lazy(() => import('views/HeroDetail/index'))
const Items = lazy(() => import('views/Items'))
const FusionSimulator = lazy(() => import('views/FusionSimulator'))
const Builder = lazy(() => import('views/Builder'))
const LeaderBoard = lazy(() => import('views/LeaderBoard/LeaderBoard'))
const BannedList = lazy(() => import('views/BannedList/BannedList'))
const WarnedList = lazy(() => import('views/WarnedList/WarnedList'))
const RecentPVPMatch = lazy(() => import('views/RecentPVPMatch/RecentPVPMatch'))
const RecentCoopMatch = lazy(() => import('views/RecentCoopMatch/RecentCoopMatch'))
const UserPVPMatchHistory = lazy(() => import('views/UserPVPMatchHistory/UserPVPMatchHistory'))
const UserCoopMatchHistory = lazy(() => import('views/UserCoopMatchHistory/UserCoopMatchHistory'))
const DesignMode = lazy(() => import('views/DesignMode/DesignMode'))
const BroadCast = lazy(() => import('views/BroadCast/BroadCast'))
const NewLink = lazy(() => import('views/NewLink/NewLink'))
const UserInfo = lazy(() => import('views/UserInfo'))
const UserActivites = lazy(() => import('views/UserInfo/UserActivites'))
const HeroesSaleHistory = lazy(() => import('views/UserInfo/HeroesSaleHistory'))
const UserListHero = lazy(() => import('views/UserInfo/UserListHero'))
const OnusWithraw = lazy(() => import('views/OnusWithraw/OnusWithraw'))
const MailBox = lazy(() => import('views/MailBox/MailBox'))
const ReportUser = lazy(() => import('views/ReportUser/ReportUser'))
const HeroAttribute = lazy(() => import('views/HeroAttribute/HeroAttribute'))
const CreateEmoji = lazy(() => import('views/Emoji/CreateEmoji'))
const ManageEmoji = lazy(() => import('views/Emoji/ManageEmoji'))
const ManageMailBox = lazy(() => import('views/MailBox/ManageMailBox'))
const CreateDailyReward = lazy(() => import('views/DailyReward/CreateDailyReward'))
const ManageDailyReward = lazy(() => import('views/DailyReward/ManageDailyReward'))
const ManageLevelOfUser = lazy(() => import('views/LevelOfUser/ManageLevelOfUser'))
const ManageReward = lazy(() => import('views/LevelOfUser/ManageReward'))
const KnowledgeBaseTextEditor = lazy(() =>
  import('views/AdminTool/KnowledgeBaseTextEditor/KnowledgeBaseTextEditor'),
)
const ManageMapSkin = lazy(() => import('views/AdminTool/Skin/MapSkin/ManageMapSkin'))
const CreateMapSkin = lazy(() => import('views/AdminTool/Skin/MapSkin/CreateMapSkin'))
const Skin = lazy(() => import('views/AdminTool/Skin/Skin'))
const CreateHeroSkin = lazy(() => import('views/AdminTool/Skin/HeroSkin/CreateHeroSkin'))
const CreateEventItems = lazy(() => import('views/AdminTool/ManageEvent/CreateEventItems'))
const CreateEvent = lazy(() => import('views/AdminTool/ManageEvent/CreateEvent'))
const ManageEventItems = lazy(() => import('views/AdminTool/ManageEvent/ManageEventItems'))
const ManageShop = lazy(() => import('views/AdminTool/ShopInGame/ManageShop'))
const CreateShopItem = lazy(() => import('views/AdminTool/ShopInGame/CreateShopItem'))
const ManageShopEvt = lazy(() => import('views/AdminTool/ShopEvent/ManageShopEvt'))
const CreateShopEvt = lazy(() => import('views/AdminTool/ShopEvent/CreateShopEvt'))
const CreateWeekendEvtData = lazy(() => import('views/AdminTool/ShopEvent/CreateWeekendEvtData'))
const CreateShopOffer = lazy(() => import('views/AdminTool/ShopInGame/CreateShopOffer'))
const ManageQuest = lazy(() => import('views/AdminTool/EventQuest/ManageQuest'))
const CreateEventQuest = lazy(() => import('views/AdminTool/EventQuest/CreateEventQuest'))
const BossAnalytics = lazy(() => import('views/AdminTool/BossAnalytics/BossAnalytics'))
const BotProfilePage = lazy(() => import('views/BotProfile'))

const routes = [
  {
    path: '/card-info',
    element: CardInfo,
  },
  {
    path: '/card-info/:id',
    element: HeroDetails,
  },
  {
    path: '/heroes-details',
    element: HeroDetail,
  },
  {
    path: '/items',
    element: Items,
  },
  {
    path: '/fusion-simulator',
    element: FusionSimulator,
  },
  {
    path: '/builder',
    element: Builder,
  },
  {
    path: '/leaderboards',
    element: LeaderBoard,
  },
  {
    path: '/warnedlist',
    element: WarnedList,
  },
  {
    path: '/bannedlist',
    element: BannedList,
  },
  {
    path: '/recent-coop-match',
    isProtect: true,
    element: RecentCoopMatch,
  },
  {
    path: '/recent-pvp-match',
    isProtect: true,
    element: RecentPVPMatch,
  },
  {
    path: '/user-match-history/:id',
    element: UserPVPMatchHistory,
    isProtect: true,
  },
  {
    path: '/user-coop-history/:id',
    element: UserCoopMatchHistory,
    isProtect: true,
  },
  {
    path: '/design',
    element: DesignMode,
    isProtect: true,
  },
  {
    path: '/broadcast',
    element: BroadCast,
    isProtect: true,
  },
  {
    path: '/new-link',
    element: NewLink,
    isProtect: true,
  },
  {
    path: '/user-info',
    element: UserInfo,
    isProtect: true,
  },
  {
    path: '/user-info/activites/:id',
    element: UserActivites,
    isProtect: true,
  },
  {
    path: '/user-info/user-hero/:id',
    element: UserListHero,
    isProtect: true,
  },
  {
    path: '/onus-withraw',
    element: OnusWithraw,
    isProtect: true,
  },
  {
    path: '/mail-box',
    element: MailBox,
    isProtect: true,
  },
  {
    path: '/report-user',
    element: ReportUser,
    isProtect: true,
  },
  {
    path: '/heroes-sale-history',
    element: HeroesSaleHistory,
    isProtect: true,
  },
  {
    path: '/hero-attribute',
    element: HeroAttribute,
  },
  {
    path: '/create-emoji',
    element: CreateEmoji,
    isProtect: true,
  },
  {
    path: '/manage-emoji',
    element: ManageEmoji,
    isProtect: true,
  },
  {
    path: '/manage-mailbox',
    element: ManageMailBox,
  },
  {
    path: '/create-daily',
    element: CreateDailyReward,
    isProtect: true,
  },
  {
    path: '/manage-daily',
    element: ManageDailyReward,
    isProtect: true,
  },
  {
    path: '/create-reward',
    element: ManageLevelOfUser,
    isProtect: true,
  },
  {
    path: '/manage-reward',
    element: ManageReward,
    isProtect: true,
  },
  {
    path: '/knowledge-base-tool',
    element: KnowledgeBaseTextEditor,
  },
  {
    path: '/map-skin',
    element: ManageMapSkin,
  },
  {
    path: '/create-map-skin',
    element: CreateMapSkin,
  },
  {
    path: '/skin',
    element: Skin,
  },
  {
    path: '/create-hero-skin',
    element: CreateHeroSkin,
    isProtect: true,
  },
  {
    path: '/create-event-item',
    element: CreateEventItems,
    isProtect: true,
  },
  {
    path: '/manage-event',
    element: CreateEvent,
    isProtect: true,
  },
  {
    path: '/event-item',
    element: ManageEventItems,
  },
  {
    path: '/manage-shop',
    element: ManageShop,
    isProtect: true,
  },
  {
    path: '/create-shop',
    element: CreateShopItem,
    isProtect: true,
  },
  {
    path: '/shop-event',
    element: ManageShopEvt,
    isProtect: true,
  },
  {
    path: '/create-shop-event',
    element: CreateShopEvt,
    isProtect: true,
  },
  {
    path: '/create-shop-offer',
    element: CreateShopOffer,
    isProtect: true,
  },
  {
    path: '/create-weekend-evt',
    element: CreateWeekendEvtData,
    isProtect: true,
  },
  {
    path: '/event-quest',
    element: ManageQuest,
    isProtect: true,
  },
  {
    path: '/create-event-quest',
    element: CreateEventQuest,
    isProtect: true,
  },
  {
    path: '/boss-analytics',
    element: BossAnalytics,
  },
  {
    path: '/bot-profile',
    element: BotProfilePage,
    isProtect: true,
  },
]
// User: 0,
// Supporter: 1,
// Mod: 2,
// Designer: 3,
// SuperMod: 4,
// Admin: 99,
// SuperAdmin:999,
// BOD:999999

export default routes
