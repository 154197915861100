import { Navigate } from 'react-router-dom'
import React, { useEffect, useState, useCallback } from 'react'
import { useStore } from 'hooks'
import { Route } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  const {
    state: { userRole },
  } = useStore()

  if (userRole > 0) {
    return children
  }

  return <Navigate to="/card-info" />
}

export default PrivateRoute
