export const ROLE = {
  User: 0,
  Supporter: 1,
  Mod: 2, //moderator
  Designer: 3,
  SuperMod: 4,
  Content: 5,
  Admin: 99,
  SuperAdmin:999,
  BOD:999999
}
