import { ROLE } from 'contants/roleConstant'

export const navbarConfig = [
  {
    label: 'Card Info',
    href: '/card-info',
  },
  {
    label: 'Items',
    href: '/items',
  },
  {
    label: 'Leaderboards',
    href: '/leaderboards',
  },
  {
    label: 'Builder',
    href: '/builder',
  },
  {
    label: 'Simulator',
    href: '/fusion-simulator',
  },
  {
    label: 'Warned List',
    href: '/warnedlist',
  },
  {
    label: 'Banned List',
    href: '/bannedlist',
  },
]

export const navbarAdminConfig = [
  {
    label: 'Recent Coop Match',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/recent-coop-match',
  },
  {
    label: 'Recent PVP Match',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/recent-pvp-match',
  },
]

export const navSelectConfig = [
  {
    label: 'Design',
    id: 'Design',
    isProtect: true,
    role: ROLE.Admin,
    href: '/design',
  },
  {
    label: 'Broad Cast',
    id: 'BroadCast',
    isProtect: true,
    role: ROLE.Admin,
    href: '/broadcast',
  },
  {
    label: 'User Info',
    id: 'UserInfo',
    isProtect: true,
    role: ROLE.Admin,
    href: '/user-info',
  },
  {
    label: 'New Link',
    id: 'NewLink',
    isProtect: true,
    role: ROLE.Admin,
    href: '/new-link',
  },
  {
    label: 'Withdraw Onus',
    id: 'WithdrawOnus',
    isProtect: true,
    role: ROLE.Admin,
    href: '/onus-withraw',
  },
  {
    label: 'Mail Box',
    id: 'MailBox',
    isProtect: true,
    role: ROLE.SuperAdmin,
    href: '/manage-mailbox',
  },
  {
    label: 'User Report',
    id: 'UserReport',
    isProtect: true,
    role: ROLE.Admin,
    href: '/report-user',
  },
  {
    label: 'Heroes sale history',
    id: 'IAPView',
    isProtect: true,
    role: ROLE.Admin,
    href: '/heroes-sale-history',
  },
  {
    label: 'Manage Emoji',
    id: 'EmojiShop',
    isProtect: true,
    role: ROLE.Admin,
    href: '/manage-emoji',
  },
  {
    label: 'Manage daily login',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/manage-daily',
  },
  {
    label: 'Knowledge Base editor',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/knowledge-base-tool',
  },
  {
    label: 'Manage Reward',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/manage-reward',
  },
  {
    label: 'Manage Event',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/manage-event',
  },
  {
    label: 'Manage Shop',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/manage-shop',
  },
  {
    label: 'Shop Event',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/shop-event',
  },
  {
    label: 'Event Quest',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/event-quest',
  },
  {
    label: 'Bot Profile',
    id: 'DailyLogin',
    isProtect: true,
    role: ROLE.Admin,
    href: '/bot-profile',
  },
]
