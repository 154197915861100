import React from 'react'
import styled from '@emotion/styled'
import {
    Box,
    useMediaQuery
} from '@mui/material'

const Container = ({children}) => {
    const isMobile = useMediaQuery('(max-width:1080px)')

    const Wrapper = styled(Box)`
        width: 100%;
        padding: ${isMobile ? '30px' : '50px 120px'}
    `


    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default Container
