import React, { useState } from 'react'
import styled from '@emotion/styled'
import { AppBar, Box, TextField, useMediaQuery, Drawer, Divider, List, Toolbar } from '@mui/material'
import LogoIcon from 'assets/icons/logo.svg'
import GoogleIcon from 'assets/icons/google.svg'
import AppleIcon from 'assets/icons/apple.svg'
import CloseImg from 'assets/icons/close.svg'
import firebase from 'firebase/firebaseConfig'
import { setCookie } from 'utils/cookie'
// import useToast from 'hooks/useToast'
import { REFRESH_TOKEN, TOKEN_ID } from 'utils/constants'
import heroestdApi from 'api/heroestdApi'
import { setLogin, updateUserInGame } from 'store/actions'
import { useStore } from 'hooks'
import {toast} from "react-toastify"

const PopupLogin = ({ close }) => {

  const {
    dispatch
  } = useStore()
  const [loginSuccess, setLoginSuccess] = useState(false)
  // const { toastSuccess, toastError } = useToast()

  const onClosePopup = () => {
    close()
  }

  const onLoginSocialNetWork = (type) => {
    let provider
    switch (type) {
      case 'facebook':
        provider = new firebase.auth.FacebookAuthProvider()
        break
      case 'apple':
        provider = new firebase.auth.OAuthProvider('apple.com')
        break
      default:
        provider = new firebase.auth.GoogleAuthProvider()
        break
    }

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        const idToken = await result.user.getIdToken(true)
        const { refreshToken } = result.user
        setCookie(TOKEN_ID, idToken)
        setCookie(REFRESH_TOKEN, refreshToken)
        // // This gives you a Google Access Token. You can use it to access the Google API.
        // const credentialMapped = Object.fromEntries(Object.entries(credential));
        // const tokenFromGG = credentialMapped.idToken
        // const tokenFromGGAccess = credentialMapped.accessToken
        
        const loginInfo = await heroestdApi.loginWithToken(idToken)
        dispatch(updateUserInGame(loginInfo.user))
        dispatch(setLogin(!!idToken))
        setLoginSuccess(true)
        toast.success("Login succeed");
        window.location.reload()
        onClosePopup();
      })
      .catch((error) => {
        toast.error(`Login with ${type} Error `, error.message);
        const errorCode = error.code
        const errorMessage = error.message
        const email = error.email
        const credential = error.credential
      })
  }

  return (
    <Wrapper>
      <Box 
      style={{ top: '10px', right: '10px', position: 'absolute', cursor: 'pointer' }} >
      <img src={CloseImg} onClick={() => onClosePopup()}
      alt="close" />
      </Box>
      <ContainerPopup>
        <CardIcon>
          <img src={LogoIcon} alt='logo' height={80} width={80} />
          <Title>Login</Title>
        </CardIcon>
      </ContainerPopup>
      <ContainerPopup style={{ alignItems: 'center', padding: '0' }} flexDirection="column">
            <div className="text-gray text-sm flex flex-col items-center">

              <div className="w-full flex flex-col items-center">
                <button
                  onClick={() => {
                    onLoginSocialNetWork('google')
                  }}
                  className="w-full flex flex-row justify-center hover:opacity-75"
                  style={{
                    marginTop: '20px',
                    border: '1px solid #969696',
                    borderRadius: '10px',
                    backgroundColor: '#454344',
                    color: 'white',
                    fontWeight: 'normal',
                    width: '304px',
                    padding: '0px',
                    height: '40px'
                  }}
                  scale="sm"
                >
                  <img src={GoogleIcon} alt='google' height={25} width={25} className='mt-1' />
                  <TitleLogin>Login with Google</TitleLogin>
                </button>

                <button
                  onClick={() => {
                    onLoginSocialNetWork('apple')
                  }}
                  className="w-full flex flex-row justify-center hover:opacity-75"
                  style={{
                    marginTop: '20px',
                    border: '1px solid #969696',
                    borderRadius: '10px',
                    backgroundColor: '#454344',
                    color: 'white',
                    fontWeight: 'normal',
                    width: '304px',
                    padding: '0px',
                    marginBottom: '30px',
                    height: '40px'
                  }}
                  scale="sm"
                >
                  <img src={AppleIcon} alt='google' height={25} width={25} className='mt-1' />
                  <TitleLogin>Login with Apple</TitleLogin>
                  
                </button>
              </div>
            </div>
          </ContainerPopup>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  background: linear-gradient(270deg, #000000b3 0, #444444c7 0.01%, #424242d1 105.1%);
  border: 1px solid #52525270;
  border-radius: 12px;
  color: #fff;
  width: 360px;
  height: auto;
  min-height: 360px;
  @media screen and (max-width: 668px) {
    width: 330px;
  }
`

const Title = styled(Box)`
  font-size: 30px;
  font-weight: 700;
  @media screen and (max-width: 668px) {
    
  }
`

const TitleLogin = styled(Box)`
  font-size: 18px;
  margin-top: 7px;
  margin-left: 5px;
`

const ContainerPopup = styled(Box)`
  padding: 40px 30px 10px 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const CardIcon = styled.div`
  position: relative;
  top: -10px;
  left: auto;
`

export default PopupLogin
