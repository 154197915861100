export const mappingContentConfigHero = (oldConfig = [], newConfig = []) => {
  if (!oldConfig.length || !newConfig.length) return
  let finalData = newConfig.map((newItem) => {
    let result = oldConfig.find((oldItem) => newItem.heroID === oldItem.heroID)
    if (result) {
      return { ...result, ...newItem }
    } else {
      return { ...newItem }
    }
  })

  const body = {
    data: finalData,
  }

  return JSON.stringify(body, null, 2)
}

export const mappingContentConfig = (oldConfig = [], newConfig = []) => {
  if (!oldConfig.length || !newConfig.length) return
  let finalData = oldConfig.map((oldItem) => {
    let result = newConfig.find((newItem) => oldItem.id === newItem.id)
    return { ...oldItem, ...result }
  })
  const body = {
    data: finalData,
  }

  return JSON.stringify(body, null, 2)
}
