export const convertTimeStampToDateString = (timestamp) => {
  const d = new Date(timestamp * 1000) // Convert the passed timestamp to milliseconds
  const yyyy = d.getFullYear()
  const mm = ('0' + (d.getMonth() + 1)).slice(-2) // Months are zero based. Add leading 0.
  const dd = ('0' + d.getDate()).slice(-2) // Add leading 0.
  let hh = d.getHours()
  let h = hh
  let min = ('0' + d.getMinutes()).slice(-2) // Add leading 0.
  let ampm = 'AM'

  if (hh > 12) {
    h = hh - 12
    ampm = 'PM'
  } else if (hh === 12) {
    h = 12
    ampm = 'PM'
  } else if (hh == 0) {
    h = 12
  }

  // ie: 2013-02-18, 8:35 AM
  const time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm

  return time
}

export const TOKEN_ID = 'TOKEN_ID'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'

export const VERSION_API = process.env.REACT_APP_VERSION_API

export const randomItem1 = [26,27,15,36,25]
export const randomItem2 = [36,20,30,31,40]
export const randomItem3 = [10,9,17,16,23]
export const randomItem4 = [10,9,17,16,23]
export const randomItem5 = [36,35,39,40,37]
export const randomItem6 = [42,36,26,24,29]
export const randomItem7 = [31,30,32,9,10]
export const randomItem8 = [15,42,37,36,35]
export const randomItem9 = [20,13,20,35,39]
export const randomItem10 = [17,10,23,19,18]
export const randomItem11 = [34,30,25,9,10]
export const randomItem12 = [42,36,30,33,37]
export const randomItem13 = [33,30,19,37,42]
export const randomItem14 = [25,29,24,32,18]
export const randomItem15 = [40,37,36,42,15]
export const randomItem16 = [33,19,31,33,30]
export const randomItem17 = [31,40,30,42,35]
export const randomItem18 = [29,24,18,33,25]
export const randomItem19 = [10,9,17,20,21]
export const randomItem20 = [17,18,24,10,9]

export const randomRune1 = [6,3,16,15,8]
export const randomRune2 = [20,8,16,7,17]
export const randomRune3 = [1,13,2,6,14]
export const randomRune4 = [1,13,19,14,10]
export const randomRune5 = [20,7,16,19,3]
export const randomRune6 = [18,17,20,19,6]
export const randomRune7 = [6,13,9,4,12]
export const randomRune8 = [8,16,3,12,13]
export const randomRune9 = [20,16,12,19,13]
export const randomRune10 = [18,1,6,4,2]
export const randomRune11 = [6,15,2,9,12]
export const randomRune12 = [12,3,7,9,8]
export const randomRune13 = [6,14,20,13,17]
export const randomRune14 = [5,18,11,20,9]
export const randomRune15 = [8,16,3,20,17]
export const randomRune16 = [6,9,14,12,17]
export const randomRune17 = [3,16,19,20,4]
export const randomRune18 = [18,6,15,5,17]
export const randomRune19 = [13,9,1,10,1]
export const randomRune20 = [13,1,18,20,2]

export const randomClass1 = [8,8]
export const randomClass2 = [5,5]
export const randomClass3 = [2,10]
export const randomClass4 = [2,7]
export const randomClass5 = [2,3]
export const randomClass6 = [8,9]
export const randomClass7 = [9,2]
export const randomClass8 = [2,5]
export const randomClass9 = [7,1]
export const randomClass10 = [7,1]
export const randomClass11 = [2,7]
export const randomClass12 = [6,2]
export const randomClass13 = [9,8]
export const randomClass14 = [4,6]
export const randomClass15 = [9,6]
export const randomClass16 = [9,5]
export const randomClass17 = [2,5]
export const randomClass18 = [8,8]
export const randomClass19 = [7,3]
export const randomClass20 = [7,2]

export const randomOrigin1 = [1,1]
export const randomOrigin2 = [10,10]
export const randomOrigin3 = [6,3]
export const randomOrigin4 = [6,6]
export const randomOrigin5 = [6,1]
export const randomOrigin6 = [6,9]
export const randomOrigin7 = [6,2]
export const randomOrigin8 = [6,10]
export const randomOrigin9 = [9,3]
export const randomOrigin10 = [10,4]
export const randomOrigin11 = [9,5]
export const randomOrigin12 = [2,4]
export const randomOrigin13 = [9,7]
export const randomOrigin14 = [6,5]
export const randomOrigin15 = [1,9]
export const randomOrigin16 = [10,7]
export const randomOrigin17 = [1,4]
export const randomOrigin18 = [10,6]
export const randomOrigin19 = [9,1]
export const randomOrigin20 = [5,3]

const calRandom5 = () => {
  return Math.floor(Math.random()*randomItem4.length)
}

const calRandom2 = () => {
  return Math.floor(Math.random()*randomClass19.length)
}

export const mockRandomRecommendedHeroesBuilder1 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass4[calRandom2()]),
    heroId: 4,
    heroOrigin: String(randomOrigin4[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem4[Math.floor(Math.random()*randomItem4.length)], 
    randomItem4[Math.floor(Math.random()*randomItem4.length)], randomItem4[Math.floor(Math.random()*randomItem4.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune4[calRandom5()], randomRune4[calRandom5()], randomRune4[calRandom5()]],
    status: 0,
    targetType: 1,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da864',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass1[calRandom2()]),
    heroId: 1,
    heroOrigin: String(randomOrigin1[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem1[Math.floor(Math.random()*randomItem1.length)], randomItem1[Math.floor(Math.random()*randomItem1.length)], randomItem1[Math.floor(Math.random()*randomItem1.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune1[calRandom5()], randomRune1[calRandom5()], randomRune1[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da865',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass8[calRandom2()]),
    heroId: 8,
    heroOrigin: String(randomOrigin8[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem8[Math.floor(Math.random()*randomItem8.length)],
    randomItem8[Math.floor(Math.random()*randomItem8.length)], randomItem8[Math.floor(Math.random()*randomItem8.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune8[calRandom5()], randomRune8[calRandom5()], randomRune8[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da866',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass14[calRandom2()]),
    heroId: 14,
    heroOrigin: String(randomOrigin14[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem14[Math.floor(Math.random()*randomItem14.length)], 
    randomItem14[Math.floor(Math.random()*randomItem14.length)], randomItem14[Math.floor(Math.random()*randomItem14.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune14[calRandom5()], randomRune14[calRandom5()], randomRune14[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da867',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass7[calRandom2()]),
    heroId: 7,
    heroOrigin: String(randomOrigin6[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem7[Math.floor(Math.random()*randomItem7.length)], 
    randomItem7[Math.floor(Math.random()*randomItem7.length)], randomItem7[Math.floor(Math.random()*randomItem7.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune7[calRandom5()], randomRune7[calRandom5()], randomRune7[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da868',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilder2 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass4[calRandom2()]),
    heroId: 4,
    heroOrigin: String(randomOrigin4[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem4[Math.floor(Math.random()*randomItem4.length)], randomItem4[Math.floor(Math.random()*randomItem4.length)], randomItem4[Math.floor(Math.random()*randomItem4.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune4[calRandom5()], randomRune4[calRandom5()], randomRune4[calRandom5()]],
    status: 0,
    targetType: 1,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da869',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass14[calRandom2()]),
    heroId: 14,
    heroOrigin: String(randomOrigin14[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem14[Math.floor(Math.random()*randomItem14.length)], randomItem14[Math.floor(Math.random()*randomItem14.length)], randomItem14[Math.floor(Math.random()*randomItem14.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune14[calRandom5()], randomRune14[calRandom5()], randomRune14[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da86a',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass13[calRandom2()]),
    heroId: 13,
    heroOrigin: String(randomOrigin13[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem13[Math.floor(Math.random()*randomItem13.length)], 
    randomItem13[Math.floor(Math.random()*randomItem13.length)], randomItem13[Math.floor(Math.random()*randomItem13.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune13[calRandom5()], randomRune13[calRandom5()], randomRune13[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da86b',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass15[calRandom2()]),
    heroId: 15,
    heroOrigin: String(randomOrigin15[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem15[Math.floor(Math.random()*randomItem15.length)], 
    randomItem15[Math.floor(Math.random()*randomItem15.length)], randomItem15[Math.floor(Math.random()*randomItem15.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune15[calRandom5()], randomRune15[calRandom5()], randomRune15[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da86c',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass8[calRandom2()]),
    heroId: 8,
    heroOrigin: String(randomOrigin8[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem8[Math.floor(Math.random()*randomItem8.length)], 
    randomItem8[Math.floor(Math.random()*randomItem8.length)], randomItem8[Math.floor(Math.random()*randomItem8.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune8[calRandom5()], randomRune8[calRandom5()], randomRune8[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da86d',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilder3 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass3[calRandom2()]),
    heroId: 3,
    heroOrigin: String(randomOrigin3[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem3[Math.floor(Math.random()*randomItem3.length)], 
    randomItem3[Math.floor(Math.random()*randomItem3.length)], randomItem3[Math.floor(Math.random()*randomItem3.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune3[calRandom5()], randomRune3[calRandom5()], randomRune3[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da86e',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass18[calRandom2()]),
    heroId: 18,
    heroOrigin: String(randomOrigin18[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem18[Math.floor(Math.random()*randomItem18.length)], randomItem18[Math.floor(Math.random()*randomItem18.length)], randomItem18[Math.floor(Math.random()*randomItem18.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune18[calRandom5()], randomRune18[calRandom5()], randomRune18[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da86f',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass6[calRandom2()]),
    heroId: 6,
    heroOrigin: String(randomOrigin6[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem6[Math.floor(Math.random()*randomItem6.length)], 
    randomItem6[Math.floor(Math.random()*randomItem6.length)], randomItem6[Math.floor(Math.random()*randomItem6.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune6[calRandom5()], randomRune6[calRandom5()], randomRune6[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da870',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass7[calRandom2()]),
    heroId: 7,
    heroOrigin: String(randomOrigin6[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem7[Math.floor(Math.random()*randomItem7.length)], randomItem7[Math.floor(Math.random()*randomItem7.length)], randomItem7[Math.floor(Math.random()*randomItem7.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune7[calRandom5()], randomRune7[calRandom5()], randomRune7[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da871',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass8[calRandom2()]),
    heroId: 8,
    heroOrigin: String(randomOrigin8[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem8[Math.floor(Math.random()*randomItem8.length)], randomItem8[Math.floor(Math.random()*randomItem8.length)], randomItem8[Math.floor(Math.random()*randomItem8.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune8[calRandom5()], randomRune8[calRandom5()], randomRune8[calRandom5()]],
    status: 0,
    targetType: 1,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da872',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilder4 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass5[calRandom2()]),
    heroId: 5,
    heroOrigin: String(randomOrigin5[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem5[Math.floor(Math.random()*randomItem5.length)], 
    randomItem5[Math.floor(Math.random()*randomItem5.length)], randomItem5[Math.floor(Math.random()*randomItem5.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune5[calRandom5()], randomRune5[calRandom5()], randomRune5[calRandom5()]],
    status: 0,
    targetType: 3,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da873',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass11[calRandom2()]),
    heroId: 11,
    heroOrigin: String(randomOrigin11[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem11[Math.floor(Math.random()*randomItem11.length)], 
    randomItem11[Math.floor(Math.random()*randomItem11.length)], randomItem11[Math.floor(Math.random()*randomItem11.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune11[calRandom5()], randomRune11[calRandom5()], randomRune11[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da874',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass3[calRandom2()]),
    heroId: 3,
    heroOrigin: String(randomOrigin3[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem3[Math.floor(Math.random()*randomItem3.length)], randomItem3[Math.floor(Math.random()*randomItem3.length)], randomItem3[Math.floor(Math.random()*randomItem3.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune3[calRandom5()], randomRune3[calRandom5()], randomRune3[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da875',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass20[calRandom2()]),
    heroId: 20,
    heroOrigin: String(randomOrigin20[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem20[Math.floor(Math.random()*randomItem20.length)], randomItem20[Math.floor(Math.random()*randomItem20.length)], randomItem20[Math.floor(Math.random()*randomItem20.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune20[calRandom5()], randomRune20[calRandom5()], randomRune20[calRandom5()]],
    status: 0,
    targetType: 1,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da876',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass10[calRandom2()]),
    heroId: 10,
    heroOrigin: String(randomOrigin10[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem10[Math.floor(Math.random()*randomItem10.length)], randomItem10[Math.floor(Math.random()*randomItem10.length)], randomItem10[Math.floor(Math.random()*randomItem10.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune10[calRandom5()], randomRune10[calRandom5()], randomRune10[calRandom5()]],
    status: 0,
    targetType: 1,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da877',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilder5 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass10[calRandom2()]),
    heroId: 10,
    heroOrigin: String(randomOrigin10[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem10[Math.floor(Math.random()*randomItem10.length)], randomItem10[Math.floor(Math.random()*randomItem10.length)], randomItem10[Math.floor(Math.random()*randomItem10.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune10[calRandom5()], randomRune10[calRandom5()], randomRune10[calRandom5()]],
    status: 0,
    targetType: 1,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da864',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass19[calRandom2()]),
    heroId: 19,
    heroOrigin: String(randomOrigin19[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem19[Math.floor(Math.random()*randomItem19.length)], randomItem19[Math.floor(Math.random()*randomItem19.length)], randomItem19[Math.floor(Math.random()*randomItem19.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune19[calRandom5()], randomRune19[calRandom5()], randomRune19[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da865',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass7[calRandom2()]),
    heroId: 7,
    heroOrigin: String(randomOrigin6[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem7[Math.floor(Math.random()*randomItem7.length)], randomItem7[Math.floor(Math.random()*randomItem7.length)], randomItem7[Math.floor(Math.random()*randomItem7.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune7[calRandom5()], randomRune7[calRandom5()], randomRune7[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da866',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass9[calRandom2()]),
    heroId: 9,
    heroOrigin: String(randomOrigin9[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem9[calRandom5()], randomItem9[calRandom5()], randomItem9[calRandom5()]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune9[calRandom5()], randomRune9[calRandom5()], randomRune9[calRandom5()]],
    status: 0,
    targetType: 3,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da871',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass2[calRandom2()]),
    heroId: 2,
    heroOrigin: String(randomOrigin2[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem2[Math.floor(Math.random()*randomItem2.length)], randomItem2[Math.floor(Math.random()*randomItem2.length)], randomItem2[Math.floor(Math.random()*randomItem2.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune2[calRandom5()], randomRune2[calRandom5()], randomRune2[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da872',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilder6 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass12[calRandom2()]),
    heroId: 12,
    heroOrigin: String(randomOrigin12[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem12[Math.floor(Math.random()*randomItem12.length)], randomItem12[Math.floor(Math.random()*randomItem12.length)], randomItem12[Math.floor(Math.random()*randomItem12.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune12[calRandom5()], randomRune12[calRandom5()], randomRune12[calRandom5()]],
    status: 0,
    targetType: 3,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da864',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass1[calRandom2()]),
    heroId: 1,
    heroOrigin: "2",
    ingameStatus: 0,
    items: [randomItem1[Math.floor(Math.random()*randomItem1.length)], randomItem1[Math.floor(Math.random()*randomItem1.length)], randomItem1[Math.floor(Math.random()*randomItem1.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune1[calRandom5()], randomRune1[calRandom5()], randomRune1[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da865',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass9[calRandom2()]),
    heroId: 9,
    heroOrigin: String(randomOrigin9[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem9[calRandom5()], randomItem9[calRandom5()], randomItem9[calRandom5()]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune9[calRandom5()], randomRune9[calRandom5()], randomRune9[calRandom5()]],
    status: 0,
    targetType: 3,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da870',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass4[calRandom2()]),
    heroId: 4,
    heroOrigin: String(randomOrigin4[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem4[Math.floor(Math.random()*randomItem4.length)], randomItem4[Math.floor(Math.random()*randomItem4.length)], randomItem4[Math.floor(Math.random()*randomItem4.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune4[calRandom5()], randomRune4[calRandom5()], randomRune4[calRandom5()]],
    status: 0,
    targetType: 1,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da867',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass2[calRandom2()]),
    heroId: 2,
    heroOrigin: String(randomOrigin2[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem2[Math.floor(Math.random()*randomItem2.length)], randomItem2[Math.floor(Math.random()*randomItem2.length)], randomItem2[Math.floor(Math.random()*randomItem2.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune2[calRandom5()], randomRune2[calRandom5()], randomRune2[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da868',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilder7 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass20[calRandom2()]),
    heroId: 20,
    heroOrigin: String(randomOrigin20[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem20[Math.floor(Math.random()*randomItem20.length)], randomItem20[Math.floor(Math.random()*randomItem20.length)], randomItem20[Math.floor(Math.random()*randomItem20.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune20[calRandom5()], randomRune20[calRandom5()], randomRune20[calRandom5()]],
    status: 0,
    targetType: 1,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da864',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass14[calRandom2()]),
    heroId: 14,
    heroOrigin: String(randomOrigin14[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem14[Math.floor(Math.random()*randomItem14.length)], randomItem14[Math.floor(Math.random()*randomItem14.length)], randomItem14[Math.floor(Math.random()*randomItem14.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune14[calRandom5()], randomRune14[calRandom5()], randomRune14[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da865',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass2[calRandom2()]),
    heroId: 2,
    heroOrigin: String(randomOrigin2[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem2[Math.floor(Math.random()*randomItem2.length)], randomItem2[Math.floor(Math.random()*randomItem2.length)], randomItem2[Math.floor(Math.random()*randomItem2.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune2[calRandom5()], randomRune2[calRandom5()], randomRune2[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da870',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass15[calRandom2()]),
    heroId: 15,
    heroOrigin: String(randomOrigin15[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem15[Math.floor(Math.random()*randomItem15.length)], randomItem15[Math.floor(Math.random()*randomItem15.length)], randomItem15[Math.floor(Math.random()*randomItem15.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune15[calRandom5()], randomRune15[calRandom5()], randomRune15[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da871',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass3[calRandom2()]),
    heroId: 3,
    heroOrigin: String(randomOrigin3[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem3[Math.floor(Math.random()*randomItem3.length)], randomItem3[Math.floor(Math.random()*randomItem3.length)], randomItem3[Math.floor(Math.random()*randomItem3.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune3[calRandom5()], randomRune3[calRandom5()], randomRune3[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da872',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilder8 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass16[calRandom2()]),
    heroId: 16,
    heroOrigin: String(randomOrigin16[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem16[calRandom5()], randomItem16[calRandom5()], randomItem16[calRandom5()]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune16[calRandom5()], randomRune16[calRandom5()], randomRune16[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da864',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass18[calRandom2()]),
    heroId: 18,
    heroOrigin: String(randomOrigin18[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem18[Math.floor(Math.random()*randomItem18.length)], randomItem18[Math.floor(Math.random()*randomItem18.length)], randomItem18[Math.floor(Math.random()*randomItem18.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune18[calRandom5()], randomRune18[calRandom5()], randomRune18[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da86f',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass3[calRandom2()]),
    heroId: 3,
    heroOrigin: String(randomOrigin3[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem3[Math.floor(Math.random()*randomItem3.length)], randomItem3[Math.floor(Math.random()*randomItem3.length)], randomItem3[Math.floor(Math.random()*randomItem3.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune3[calRandom5()], randomRune3[calRandom5()], randomRune3[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da870',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass9[calRandom2()]),
    heroId: 9,
    heroOrigin: String(randomOrigin9[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem9[calRandom5()], randomItem9[calRandom5()], randomItem9[calRandom5()]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune9[calRandom5()], randomRune9[calRandom5()], randomRune9[calRandom5()]],
    status: 0,
    targetType: 3,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da871',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass17[calRandom2()]),
    heroId: 17,
    heroOrigin: String(randomOrigin17[calRandom2()]),
    ingameStatus: 0,
    // items: [Math.random(randomItem17), Math.random(randomItem17), Math.random(randomItem17)],
    items: [randomItem17[Math.floor(Math.random()*randomItem17.length)], randomItem17[Math.floor(Math.random()*randomItem17.length)], randomItem17[Math.floor(Math.random()*randomItem17.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune17[calRandom5()], randomRune17[calRandom5()], randomRune17[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da872',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilder9 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass19[calRandom2()]),
    heroId: 19,
    heroOrigin: String(randomOrigin19[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem19[Math.floor(Math.random()*randomItem19.length)], randomItem19[Math.floor(Math.random()*randomItem19.length)], randomItem19[Math.floor(Math.random()*randomItem19.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune19[calRandom5()], randomRune19[calRandom5()], randomRune19[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da864',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass7[calRandom2()]),
    heroId: 7,
    heroOrigin: String(randomOrigin6[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem7[Math.floor(Math.random()*randomItem7.length)], randomItem7[Math.floor(Math.random()*randomItem7.length)], randomItem7[Math.floor(Math.random()*randomItem7.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune7[calRandom5()], randomRune7[calRandom5()], randomRune7[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da865',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass8[calRandom2()]),
    heroId: 8,
    heroOrigin: String(randomOrigin8[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem8[Math.floor(Math.random()*randomItem8.length)], randomItem8[Math.floor(Math.random()*randomItem8.length)], randomItem8[Math.floor(Math.random()*randomItem8.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune8[calRandom5()], randomRune8[calRandom5()], randomRune8[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da875',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass2[calRandom2()]),
    heroId: 2,
    heroOrigin: String(randomOrigin2[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem2[Math.floor(Math.random()*randomItem2.length)], randomItem2[Math.floor(Math.random()*randomItem2.length)], randomItem2[Math.floor(Math.random()*randomItem2.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune2[calRandom5()], randomRune2[calRandom5()], randomRune2[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da876',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass12[calRandom2()]),
    heroId: 12,
    heroOrigin: String(randomOrigin12[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem12[Math.floor(Math.random()*randomItem12.length)], randomItem12[Math.floor(Math.random()*randomItem12.length)], randomItem12[Math.floor(Math.random()*randomItem12.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune12[calRandom5()], randomRune12[calRandom5()], randomRune12[calRandom5()]],
    status: 0,
    targetType: 3,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da877',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilder10 = [
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass9[calRandom2()]),
    heroId: 9,
    heroOrigin: String(randomOrigin9[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem9[calRandom5()], randomItem9[calRandom5()], randomItem9[calRandom5()]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune9[calRandom5()], randomRune9[calRandom5()], randomRune9[calRandom5()]],
    status: 0,
    targetType: 3,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da866',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass10[calRandom2()]),
    heroId: 10,
    heroOrigin: String(randomOrigin10[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem10[Math.floor(Math.random()*randomItem10.length)], randomItem10[Math.floor(Math.random()*randomItem10.length)], randomItem10[Math.floor(Math.random()*randomItem10.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune10[calRandom5()], randomRune10[calRandom5()], randomRune10[calRandom5()]],
    status: 0,
    targetType: 1,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da867',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass13[calRandom2()]),
    heroId: 13,
    heroOrigin: String(randomOrigin13[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem13[Math.floor(Math.random()*randomItem13.length)], randomItem13[Math.floor(Math.random()*randomItem13.length)], randomItem13[Math.floor(Math.random()*randomItem13.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune13[calRandom5()], randomRune13[calRandom5()], randomRune13[calRandom5()]],
    status: 0,
    targetType: 4,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da875',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass3[calRandom2()]),
    heroId: 3,
    heroOrigin: String(randomOrigin3[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem3[Math.floor(Math.random()*randomItem3.length)], randomItem3[Math.floor(Math.random()*randomItem3.length)], randomItem3[Math.floor(Math.random()*randomItem3.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune3[calRandom5()], randomRune3[calRandom5()], randomRune3[calRandom5()]],
    status: 0,
    targetType: 2,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da876',
    teamBuider: true,
  },
  {
    author: '',
    dob: '',
    fusionTime: 0,
    gen: 3,
    heroClass: String(randomClass12[calRandom2()]),
    heroId: 12,
    heroOrigin: String(randomOrigin12[calRandom2()]),
    ingameStatus: 0,
    items: [randomItem12[Math.floor(Math.random()*randomItem12.length)], randomItem12[Math.floor(Math.random()*randomItem12.length)], randomItem12[Math.floor(Math.random()*randomItem12.length)]],
    parent1: '0',
    parent2: '0',
    runes: [randomRune12[calRandom5()], randomRune12[calRandom5()], randomRune12[calRandom5()]],
    status: 0,
    targetType: 3,
    tokenId: '',
    __v: 0,
    _id: '61dc67c8961ad73c340da877',
    teamBuider: true,
  },
]

export const mockRandomRecommendedHeroesBuilderArray = [
  mockRandomRecommendedHeroesBuilder1,
  mockRandomRecommendedHeroesBuilder2,
  mockRandomRecommendedHeroesBuilder3,
  mockRandomRecommendedHeroesBuilder4,
  mockRandomRecommendedHeroesBuilder5,
  mockRandomRecommendedHeroesBuilder6,
  mockRandomRecommendedHeroesBuilder7,
  mockRandomRecommendedHeroesBuilder8,
  mockRandomRecommendedHeroesBuilder9,
  mockRandomRecommendedHeroesBuilder10
]
