import { ThemeProvider } from '@mui/material/styles'
import heroestdApi from 'api/heroestdApi'
import Container from 'components/container/Container'
import PrivateRoute from 'components/container/PrivateRoute/PrivateRoute'
import { theme } from 'style/theme'
import { useStore } from 'hooks'
import React, { Suspense, useCallback, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import routes from 'routes'
import { actions } from 'store'
import { getCookie, TOKEN_ID } from 'utils'
import Navbar from 'views/Navbar/Navbar'
import './App.css'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns';

function App() {
  const { dispatch } = useStore()

  const getDataConfig = useCallback(async () => {
    const promiseList = [
      heroestdApi.getHeroConfig(),
      heroestdApi.getHeroStatConfig(),
      heroestdApi.getItemConfig(),
      heroestdApi.getRuneConfig(),
      heroestdApi.getHeroStatic(),
      heroestdApi.getClassConfig(),
      heroestdApi.getOriginConfig(),
    ]
    Promise.all(promiseList).then((resp) => {
      dispatch(actions.setHeroConfig(resp[0].data))
      dispatch(actions.setHeroStatConfig(resp[1].data))
      dispatch(actions.setItemConfig(resp[2].data))
      dispatch(actions.setRuneConfig(resp[3].data))
      dispatch(actions.setHeroStatic(resp[4].data))
      dispatch(actions.setClassConfig(resp[5].data))
      dispatch(actions.setOriginConfig(resp[6].data))
    })
  }, [dispatch])

  useEffect(() => {
    getDataConfig()
  }, [getDataConfig])

  const fetchPermission = useCallback(async () => {
    if (!getCookie(TOKEN_ID)) return
    try {
      const {
        data: { permission, name },
      } = await heroestdApi.getDetailtRolePermission()
      dispatch(actions.setPermission(permission))
      dispatch(actions.setRoleName(name))
    } catch (error) {}
  }, [])

  useEffect(() => {
    fetchPermission()
  }, [fetchPermission])

  toast.configure()
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ToastContainer autoClose={5000} />

        <Router>
          <Navbar />
          <Container>
            <Suspense fallback={<div>Loading ...</div>}>
              <Routes>
                {routes.map(({ path, element, isProtect }) => {
                  const Element = element
                  if (isProtect) {
                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <PrivateRoute>
                            <Element />
                          </PrivateRoute>
                        }
                      />
                    )
                    // return <PrivateRoute key={path} path={path} element={element} />
                  }

                  return <Route key={path} path={path} element={<Element />} />
                })}
              </Routes>
            </Suspense>
          </Container>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
