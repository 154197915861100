import { red, blue, grey, green } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: red[500],
      mode: 'dark',
    },
    secondary: {
      main: blue[500],
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: 'white',
          fontSize: 14,
          backgroundColor: "#272727",
          padding: 10,
        },
        icon: {
          color: red[500],
        },
      },
    },
  },
})
