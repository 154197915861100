import React, { useEffect, useState, useCallback } from 'react'
import {
  AppBar,
  Box,
  TextField,
  useMediaQuery,
  Drawer,
  Divider,
  List,
  Toolbar,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SearchIcon from '@mui/icons-material/Search'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import styled from '@emotion/styled'
import Popup from 'reactjs-popup'
import { styled as muiStyled, useTheme } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as LogoIcon } from 'assets/icons/wiki-logo.svg'
import { navbarAdminConfig, navbarConfig, navSelectConfig } from './config'
import Logo from 'assets/icons/logo.svg'
import firebase from 'firebase/firebaseConfig'
import { Menu, MenuButton, MenuList } from '@chakra-ui/react'
import ArrowDown from 'assets/icons/arrow-down.png'
import { toast } from 'react-toastify'
import heroestdApi from 'api/heroestdApi'
import { useStore } from 'hooks'
import { actions } from 'store'
import PopupLogin from 'views/Login/PopupLogin'
import { setCookie, getCookie, removeCookie } from 'utils/cookie'
import { TOKEN_ID, REFRESH_TOKEN } from 'utils/constants'
import { setLogin, updateUserInGame } from 'store/actions'
import LogoutIcon from 'assets/icons/logout.png'

const drawerWidth = 240

const Navbar = (props) => {
  const { state, dispatch } = useStore()

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [userAcc, setUserAcc] = useState()
  const [role, setRole] = useState()
  const isMobile = useMediaQuery('(max-width:740px)')
  const idToken = getCookie(TOKEN_ID)
  const initData = useCallback(async () => {
    const userData = await heroestdApi.loginWithToken(idToken)
    const userRole = await heroestdApi.getUserRole(idToken)
    dispatch(actions.setUserRole(userRole?.data?.role))
    setUserAcc(userData.user)
    setRole(userRole)
    dispatch(actions.updateUserInGame(userData.user))
  }, [dispatch, idToken])

  const logout = () => {
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase
      .auth()
      .signOut()
      .then(() => {
        removeCookie(TOKEN_ID)
        removeCookie(REFRESH_TOKEN)
        // dispatch(setLogin(false))
        dispatch(setLogin(false))
        dispatch(updateUserInGame(null))
        // history.push('/home')
        // setToken(undefined)
        // const toast = toastSuccess
        toast(`Logout succeed`)
        window.location.reload()
      })
      .catch((error) => {
        // An error happened.
        // const toast = toastError
        toast(`Logout failed: ${error}`)
      })
  }

  useEffect(() => {
    initData()
  }, [initData])

  const redirecRoute = (route) => {
    navigate(route)
  }

  useEffect(() => {
    if (pathname === '/') {
      navigate('card-info')
    }
  }, [navigate, pathname])

  const menuActive = (href) => {
    return pathname.includes(href)
  }

  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <StyledBar position="static">
      <MainSection
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '2px solid #686868',
              borderRadius: '8px',
              transition: '0.2s ease-in',
            },
            '&:hover fieldset': {
              border: '2px solid #686868',
              borderRadius: '8px',
            },
            '&.Mui-focused fieldset': {
              border: '2px solid #f7f7f7',
              borderRadius: '8px',
            },
          },
          justifyContent: isMobile ? 'center' : 'space-between',
          padding: isMobile ? null : '0 120px',
        }}
      >
        {isMobile ? (
          <>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <LogoIcon />
            </Toolbar>
          </>
        ) : null}

        {isMobile ? null : (
          <>
            <LogoIcon />
            <SearchField id="outlined-basic" placeholder="Search" variant="outlined" />
          </>
        )}
      </MainSection>
      {isMobile ? null : (
        <BottomHeader>
          <MenuSection>
            <div style={{ display: 'flex', gap: '20px' }}>
              {navbarConfig.map((entry) => {
                if ((entry.isProtect && state.userRole > 0) || !entry.isProtect) {
                  return (
                    <MenuItems
                      className={menuActive(entry.href) ? 'focused ' : ''}
                      key={entry.label}
                      onClick={() => redirecRoute(entry.href)}
                    >
                      {entry.label}
                    </MenuItems>
                  )
                }
              })}
              {navbarAdminConfig.map((entry) => {
                if (state.userPermission[entry.id]?.Get || state.userRole === 999999) {
                  return (
                    <MenuItems
                      className={menuActive(entry.href) ? 'focused ' : ''}
                      key={entry.label}
                      onClick={() => redirecRoute(entry.href)}
                    >
                      {entry.label}
                    </MenuItems>
                  )
                }
              })}
              {state.userRole > 0 && (
                <Menu>
                  <MenuButton as="button">
                    <AccountItems className="flex flex-row pr-2">
                      <h1 className="mx-3"> Admin page </h1>
                      <AccountArrow src={ArrowDown} />
                    </AccountItems>
                  </MenuButton>
                  <div style={{ zIndex: '99' }}>
                    <MenuList>
                      {/* {navSelectConfig.map((entry) => {
                        if ((entry.isProtect && state.userRole > 0) || !entry.isProtect) {
                          return <DropMenu
                            key={entry.label}
                            onClick={() => redirecRoute(entry.href)}>
                            {entry.label}</DropMenu>
                        }
                      })} */}
                      {navSelectConfig.map((entry) => {
                        if (state.userPermission[entry.id]?.Get || state.userRole === 999999) {
                          return <DropMenu
                            key={entry.label}
                            onClick={() => redirecRoute(entry.href)}>
                            {entry.label}</DropMenu>
                        }
                      })}
                    </MenuList>
                  </div>
                </Menu>
              )}
            </div>
            <div className="flex justify-end">
              {userAcc ? (
                <div className="flex flex-row">
                  <Menu>
                    <MenuButton as="button">
                      <AccountItems className="flex flex-row pr-2">
                        <h1 className="mx-3"> Welcome, {userAcc?.name} </h1>
                        <AccountArrow src={ArrowDown} />
                      </AccountItems>
                    </MenuButton>
                    <MenuList>
                      <Menucard className="justify-between" onClick={() => logout()}>
                        <h1 className="ml-3">Logout</h1>
                        <img src={LogoutIcon} alt="logo" height={20} width={20} className="mr-3" />
                      </Menucard>
                    </MenuList>
                  </Menu>
                  <h1 className="sm:text-sm 2xl:text-base">
                    {' '}
                    {state?.roleName && state?.roleName}{' '}
                  </h1>
                </div>
              ) : (
                <div className="flex justify-end" style={{ witdh: '150px' }}>
                  <Popup
                    className="w-full"
                    modal
                    trigger={
                      <RightMenuItems className="flex flex-row p-2">
                        <img src={Logo} alt="logo" height={40} width={40} className="ml-5" />
                        <h1 className="mx-3 font-bold md:text-lg 2xl:text-base">Login</h1>
                      </RightMenuItems>
                    }
                  >
                    {(close) => <PopupLogin close={close} />}
                  </Popup>
                </div>
              )}
            </div>
          </MenuSection>
        </BottomHeader>
      )}
      <div open={open}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerToggle}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {navbarConfig.map((entry, index) => (
              <ListItem onClick={() => redirecRoute(entry.href)} button key={entry}>
                <ListItemText primary={entry.label} />
              </ListItem>
            ))}

            {userAcc ? (
              <ListItem>
                <ListItemText onClick={() => logout()} primary="Logout" />
              </ListItem>
            ) : (
              <Popup
                className="w-full"
                modal
                closeOnDocumentClick={false}
                trigger={
                  <ListItem>
                    <ListItemText primary="Login" />
                  </ListItem>
                }
              >
                {(closeLog) => <PopupLogin close={closeLog} />}
              </Popup>
            )}
          </List>
        </Drawer>
      </div>
    </StyledBar>
  )
}
const DrawerHeader = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

const StyledBar = styled(AppBar)`
  background: transparent;
  box-shadow: none;
`
const MainSection = styled(Box)`
  min-height: 200px;
  background-image: url('images/Header/header-img.png');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  display: flex;
  align-items: center;
`
const SearchField = styled(TextField)`
  input {
    background-color: #272727;
    color: #fff;
    border-radius: 8px;
    padding: 13.5px 14px;
    width: 310px;
  }
`
const BottomHeader = styled(Box)`
  min-height: 52px;
  background: #000;
  display: flex;
  align-items: center;
  padding: 0 60px;
  @media screen and (max-width: 1440px) {
    padding: 0 5px;
  }
`
const MenuSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  .focused {
    color: #ffc247;
    font-weight: 700;
  }
  width: 100%;
`
const MenuItems = styled.a`
  color: #9e9e9e;
  text-decoration: none;
  &:hover {
    color: #ffc247;
  }
  @media screen and (min-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
  @media screen and (min-width: 1324px) {
    font-size: 14px;
    line-height: 14px;
  }

  @media screen and (min-width: 1800px) {
    font-size: 18px;
    line-height: 28px;
  }
`

const RightMenuSection = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  .focused {
    color: #ffc247;
    font-weight: 700;
  }
`
const RightMenuItems = styled.a`
  color: #9e9e9e;
  background: #202020;
  border-radius: 10px;
  text-decoration: none;
  &:hover {
    color: #ffc247;
  }
  @media screen and (min-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 14px;
    line-height: 14px;
  }

  @media screen and (min-width: 1800px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const AccountArrow = styled.img`
  height: 8px;
  width: 8px;
  @media screen and (min-width: 768px) {
    height: 10px;
    width: 10px;
  }

  @media screen and (min-width: 1024px) {
    height: 14px;
    width: 14px;
  }

  @media screen and (min-width: 1800px) {
    height: 18px;
    width: 18px;
    margin-top: 4px;
  }
`

const AccountItems = styled.a`
  font-size: 8px;
  line-height: 8px;
  @media screen and (min-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 14px;
    line-height: 14px;
  }

  @media screen and (min-width: 1800px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Menucard = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  .focused {
    color: #ffc247;
    font-weight: 700;
  }
  background: #151419;
  padding: 10px;
  width: 220px;
  border: 2px solid #808080;
  border-radius: 10px;
  &:hover {
    background: #808080;
    color: #ffc247;
  }
`

const DropMenu = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  .focused {
    color: #ffc247;
    font-weight: 700;
  }
  background: #151419;
  padding: 10px;
  width: 220px;
  border: 1px solid #808080;
  &:hover {
    background: #808080;
    color: #ffc247;
  }
`

export default Navbar
